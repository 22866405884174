<template>
    <div>
        <el-row>
            <el-col :span="24">
                <el-form :inline="true">
                    <el-form-item label="昵称:">
                        <el-input
                            v-model="filters.keyWord"
                            placeholder="请输入关键字"
                            clearable
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label='缴费状态:'>
                        <el-select v-model="filters.isPay" placeholder="请选择" clearable>
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='数据来源:'>
                        <el-select v-model="filters.dataSource" placeholder="请选择" clearable>
                            <el-option
                            v-for="item in dataSourceArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label='标签:'>
                        <el-select v-model="filters.labelTy" placeholder="请选择" clearable>
                            <el-option
                            v-for="item in labelTypeArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label='启用标识:'>
                        <el-select v-model="filters.isEnable" placeholder="请选择" clearable @clear='handleClear'>
                            <el-option
                            v-for="item in options1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item>
                        <toolbar
                            :buttonList="buttonList"
                            @callFunction="callFunction"
                        ></toolbar>
                    </el-form-item>
                </el-form>
                </el-col>
        </el-row>
        <!-- 表格主体 -->
        <el-table
            border
            highlight-current-row
            :data="tableData"
            v-loading="listLoading"
            :cell-style='rowClass'
            :header-cell-style='headerClass'
            style="width: 100%"
        >
            <el-table-column type='index' label='序号' width='60'></el-table-column>
            <el-table-column prop="Name" label="姓名" width='80'></el-table-column>
            <el-table-column prop="PhoneNumber" label="短信验证手机号"></el-table-column>
            <el-table-column prop="RequestSource" label="数据来源"></el-table-column>
            <!-- <el-table-column prop="ExamProjectName" label="项目名称"></el-table-column> -->
            <el-table-column prop='TradeName' label='行业名称'></el-table-column>
            <el-table-column prop='TradeNotes' label='行业简介' show-overflow-tooltip></el-table-column>
            <el-table-column prop='StationName' label='岗位名称'></el-table-column>
            <el-table-column prop='StationNotes' label='岗位简介' show-overflow-tooltip></el-table-column>
            <!-- <el-table-column prop="IsPay" label="是否缴费">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsPay == 0' type='primary'>无需缴费</el-tag>
                    <el-tag v-if='scope.row.IsPay == 1' type='success'>已缴费</el-tag>
                    <el-tag v-if='scope.row.IsPay == 2' type='danger'>未缴费</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="PayAmount" label="需缴费金额"></el-table-column>
            <el-table-column prop="PayTime" label="缴费时间"></el-table-column>
            <el-table-column prop="PayWay" label="缴费方式">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.PayWay == 0'>支付宝</el-tag>
                    <el-tag v-if='scope.row.PayWay == 1'>微信</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="PayOrderNo" label="缴费单号"></el-table-column> -->
            <el-table-column prop="IsPass" label="是否取证" fixed="right">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsPass' type='success'>是</el-tag>
                    <el-tag v-else type='warning'>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='IsVerification' label='是否验证' fixed="right">
                <template slot-scope='scope'>
                    <el-tag :type="scope.row.IsVerification == true ? 'success' : 'warning' ">{{scope.row.IsVerification == true ? '是' : '否'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='StatusName' label='状态' fixed="right"></el-table-column>
            <!-- <el-table-column prop="IsEnable" label='启用标识' fixed="right">
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsEnable== true ? "success" : "warning"'>{{scope.row.IsEnable == true ? '启用' : '禁用'}}</el-tag>
                </template>               
            </el-table-column> -->
            <el-table-column prop="Remarks" label="备注" fixed="right"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="this.pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.pages.dataCount"
        class='pageBar'>
        </el-pagination>
        <!-- 新增 -->
        <el-dialog
            :title="isAddOrEdit?'新增':'编辑'"
            :visible.sync="addFormDialog"
            v-model="addFormDialog"
            @close="dialogCloseFun"
            :close-on-click-modal="false"
        >
            <el-form :model="dialogForm" :rules="dialogFormRules" ref="dialogFormRef" label-width="150px" enctype="multipart/form-data">
                <el-form-item label='姓名:' prop='name'>
                    <el-input v-model='dialogForm.name' clearable></el-input>
                </el-form-item>
                <el-form-item label='手机号:' prop='phone'>
                    <el-input v-model='dialogForm.phone' clearable></el-input>
                </el-form-item>
                <el-form-item label='身份证号:' prop='userCard'>
                    <el-input v-model='dialogForm.userCard' @blur="onBlur(dialogForm.userCard)" clearable></el-input>
                </el-form-item>
                <el-form-item label='性别:'>
                    <el-select v-model="dialogForm.userSex" :disabled="true" placeholder="请选择" clearable>
                        <el-option
                            v-for="item in sexArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label='单位名称:' prop='unitName'>
                    <el-input v-model='dialogForm.unitName' clearable></el-input>
                </el-form-item> -->
                <el-form-item label='单位名称:' prop='unitName'>
                    <el-select v-model="dialogForm.unitName" filterable remote :remote-method="searchFun" placeholder="请搜索/选择" clearable class="social">
                        <el-option
                            v-for="item in SocialUnitList"
                            :key="item.ID"
                            :label="item.UnitName"
                            :value="item.ID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='通讯地址:' prop='unitAddress'>
                    {{dialogForm.unitAddress}}
                    <!-- <el-input v-model='dialogForm.unitAddress' clearable></el-input> -->
                </el-form-item>
                <el-form-item label='岗位分类:' prop='postType'>
                    <el-select v-model="dialogForm.postType" placeholder="请选择" clearable>
                        <el-option
                            v-for="item in postTypeArr"
                            :key="item.Code"
                            :label="item.Name"
                            :value="item.Code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='行业分类:' prop='industryType'>
                    <el-select v-model="dialogForm.industryType" :disabled="isDisabled" placeholder="请选择" clearable>
                        <el-option
                            v-for="item in industryTypeArr"
                            :key="item.Code"
                            :label="item.Name"
                            :value="item.Code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='证件照:'>
                    <el-upload
                    :action="uploadImagesUrl"
                    list-type="picture-card"
                    :before-upload="beforeUpload"
                    :on-success="handle_success"
                    :headers="headers"
                    ref="uploadRef"
                    :auto-upload="true">
                        <i slot="default" class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{file}">
                        <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url" alt=""
                        >
                        <span class="el-upload-list__item-actions">
                            <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                            >
                            <i class="el-icon-delete"></i>
                            </span>
                        </span>
                        </div>
                    </el-upload>
                </el-form-item>
                <!-- <el-form-item label='标签:'>
                    <el-select v-model="filters.labelType" placeholder="请选择" clearable>
                        <el-option
                            v-for="item in labelTypeArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormDialog = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保存</el-button>
            </div>
        </el-dialog>
        <!-- 导入对话框 -->
        <el-dialog
            title="导入"
            :visible.sync="importDialogVisible"
            :close-on-click-modal="true"
            width="500px"
        >
            <el-upload
                drag
                :limit='1'
                ref="upload"
                :on-error="submitFileError"
                :on-success="submitFileSuccess"
                :before-upload="beforeUploadFile"
                :on-exceed="submitFileMore"
                :show-file-list="false"
                :action="actions"
                :headers='headers'
                :multiple='false'
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传文件，且不超过2MB</div>
            </el-upload>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importDialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Toolbar from "../../components/Toolbar";
import Qs from "qs";
import { getButtonList } from "../../promissionRouter";
import { getExamEnrollInfoListPageNew,submitStudentInformationByManger,importStudentExamEnrollInfoUrl,exportExamEnrollInfo,getDataDictionaryList,getSocialUnitListPage,getCheckIsSocialUnitStaff } from '@/api/api'
import { validPhone,valididentityCard } from "../../../util/validate";
import util from "../../../util/date";
import api from '@/api'
export default {
    components: { Toolbar },
    data(){
        //验证手机号
        let isPhoneNumberNum = (rule, value, callback) => {
            if (!validPhone(value)) {
                return callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        //验证身份证号
        let isidentityCardNumberNum = (rule, value, callback) => {
            if (!valididentityCard(value)) {
                return callback(new Error("请输入正确的身份证号"));
            } else {
                callback();
            }
        };
        return {
            // 筛选项
            filters:{
                keyWord:'',
                isPay:null,
                labelTy:'',
                dataSource:'',
                isEnable:'',
            },
            options:[
                {label:'无需缴费',value:0},
                {label:'已缴费',value:1},
                {label:'未缴费',value:2},
            ],
            options1:[
                {label:'启用',value:true},
                {label:'禁用',value:false}
            ],
            // 分页
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
            // 表格数据
            tableData:[],
            buttonList:[],
            listLoading:false,
            // 新增
            isAddOrEdit: true,
            addFormDialog: false,
            addLoading: false,
            imgsizePass: true,
            uploadImagesUrl: '',
            BackgroundWebImgUrl: '',
            dialogForm: {
                name: '',
                phone: '',
                userCard: '',
                userSex: '',
                unitName: '',
                unitAddress: '-',
                postType: '',
                industryType: '',
                labelType: '',
            },
            isDisabled: false,
            imageUrlDataStamp: '',
            dialogFormRules:{
                name:[{required:true,message:'请输入姓名',trigger:'blur'}],
                phone:[
                    {required:true,message:'请输入手机号',trigger:'blur'},
                    { validator: isPhoneNumberNum },
                ],
                userCard:[
                    {required:true,message:'请输入身份证号',trigger:'blur'},
                    { validator: isidentityCardNumberNum },
                ],
                unitName:[{required:true,message:'请输入单位名称',trigger:'blur'}],
                postType:[{required:true,message:'请选择岗位分类',trigger:'blur'}],
                industryType:[{required:true,message:'请选择行业分类',trigger:'blur'}],
            },
            fileList:[],
            SocialUnitList:[],
            sexArr: [
                {label:'男',value:1},
                {label:'女',value:0}
            ],
            postTypeArr: [],
            industryTypeArr: [],
            labelTypeArr: [
                {label:'广东信用',value:1},
                {label:'其他',value:2}
            ],
            dataSourceArr: [
                {label:'Android',value:1},
                {label:'IOS',value:2},
                {label:'PC',value:3},
                {label:'MAC',value:4},
            ],
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            // 导入
            importDialogVisible:false,
            actions:'',
        }
    },
    methods:{
        // 列表样式
        rowClass(){
            return 'text-align:center;'
        },
        headerClass(){
            return 'text-align:center;'
        },
        // 筛选项清空重新获取数据
        handleClear(){
            this.getData()
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.pageIndex = value
            this.getData()
        },
        // 查询
        getExamEnrollInfoListPageNew(){
            this.getData()
        },
        // 获取表格数据
        getData(){
            var params = {
                keyWord:this.filters.keyWord ? this.filters.keyWord : '',
                isPay:this.filters.isPay ? this.filters.isPay : '',
                isEnable:this.filters.isEnable,
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize,
            }
            this.listLoading = true
            getExamEnrollInfoListPageNew(params).then(res => {
                if(res.data.Success){
                    this.listLoading = false
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.listLoading = false
                    this.$message({
                        message:res.data.Message,
                        type:'error'
                    })
                }
            })
        },
        // 新增
        handleAdd() {
            this.addFormDialog = true
            this.isAddOrEdit = true
            this.addLoading = false
            this.dialogForm = {
                name: '',
                phone: '',
                userCard: '',
                userSex: '',
                unitName: '',
                unitAddress: '-',
                postType: '',
                industryType: '',
                labelType: '',
            }
        },
        // 导入
        handleImport() {
            this.importDialogVisible = true
            this.actions = importStudentExamEnrollInfoUrl
        },
        // 文件上传失败
        submitFileError(err,file,fileList){
            this.$message.error('文件上传失败!')
            this.$refs.upload.clearFiles() 
        },
        // 文件上传成功
        submitFileSuccess(res,file,fileList) {
            if(res.Success){
                this.$message({
                    message:'文件上传成功！',
                    type:'success'
                })
                this.getData();
                this.importDialogVisible = false
                this.$refs.upload.clearFiles() 
            }else{
                this.$message({
                    message:res.Message,
                    type:'error'
                })
                this.$refs.upload.clearFiles() 
            }
        },
        // 文件上传前限制
        beforeUploadFile(file) {
            const isLt2M = file.size / 1024 / 1024 > 2;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {
                this.$message.error('只能上传文件!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传文件大小不能超过 2MB!');
                return false;
            }
        },
        submitFileMore(files) {
            this.$message({
                message:'只能上传一个文件！',
                type:'warning'
            })
        },
        // 导出
        handleExport() {
            var params = {
                keyWord:this.filters.keyWord ? this.filters.keyWord : '',
                isPay:this.filters.isPay ? this.filters.isPay : '',
                isEnable:this.filters.isEnable,
                topCount:this.pages.pageSize,
            }
            exportExamEnrollInfo(params).then(res => {
                if(res.data.Status == 200){
                    window.open(res.data.Response)

                    // const aLink = document.createElement("a");
                    // let blob = new Blob([res.data.Response], {type: "application/vnd.ms-excel"})
                    // aLink.href = URL.createObjectURL(blob)
                    // aLink.setAttribute('download', '考生信息' + '.xlsx') //导出文件赋命名+格式
                    // aLink.click()
                }else{
                    this.$message.error(result.Message)
                }
            })
        },
        // 提交
        addSubmit() {
            var params = {
                Name: this.dialogForm.name,
                PhoneNumber: this.dialogForm.phone,
                CardNo: this.dialogForm.userCard,
                Gender: this.dialogForm.userSex,
                // unitName: this.dialogForm.unitName,
                StationCode: this.dialogForm.postType,
                TradeCode: this.dialogForm.industryType,
                HeadPhotoUrl: this.imageUrlDataStamp.webupurl,
                IsVerification: true,
            }
            this.SocialUnitList.forEach(item => {
                if(item.ID == this.dialogForm.unitName){
                    params.SocialUnitID = item.ID
                }
            })
            this.addLoading = true
            submitStudentInformationByManger(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.$message.success(result.Message)
                    this.getData()
                    this.addFormDialog = false
                } else {
                    this.$message.error(result.Message)
                }
                this.addLoading = false
            })
        },
        // 编辑dialog关闭
        dialogCloseFun() {
            this.$refs['uploadRef'].clearFiles()
            var imageHtml = document.getElementsByClassName('el-upload--picture-card')[0]
            imageHtml.style.cssText = 'display: block;'
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 图片上传之前函数
        beforeUpload(file) {
            var _this = this
            const isLt2M = file.size / 1024 / 1024 > 0.81;
            const isLt30K = file.size / 1024 / 1024 < 0.039;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {} else {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传图片大小不能超过 800KB!');
                return false
            }
            if (!isLt30K === false) {
                this.$message.error('上传图片须大于或等于40KB!');
                return false
            }
            const isSize = new Promise(function(resolve,reject) {
                let width = 413
                let height = 626
                let _URL = window.URL || window.webkitURL
                let img = new Image()
                img.onload = function() {
                    let valid = img.width >= width && img.height >= height
                    if(!valid){
                        _this.imgsizePass = false
                        return false
                    }else{
                        _this.imgsizePass = true
                    }
                }
                img.src = _URL.createObjectURL(file)
            })
        },
        // 图片上传成功时的函数
        handle_success(res) {
            if(this.imgsizePass) {
                if(res.Success) {
                    this.$message.success('图片上传成功')
                    this.imageUrlDataStamp = res.Response
                    this.BackgroundWebImgUrl = res.Response.weburl
                    var imageHtml = document.getElementsByClassName('el-upload--picture-card')[0]
                    imageHtml.style.cssText = 'display: none;'
                }else{
                    this.$message.error(res.Message)
                    var imageHtmlRemo = document.getElementsByClassName('el-upload-list el-upload-list--picture-card')[0]
                    var pObjs = imageHtmlRemo.childNodes;
                    for (var i = pObjs.length - 1; i >= 0; i--) {
                        imageHtmlRemo.removeChild(pObjs[i]);
                    }
                }
            }else{
                var imageHtmlRemo = document.getElementsByClassName('el-upload-list el-upload-list--picture-card')[0]
                var pObjs = imageHtmlRemo.childNodes;
                for (var i = pObjs.length - 1; i >= 0; i--) {
                    imageHtmlRemo.removeChild(pObjs[i]);
                }
                this.$message.error('上传图片尺寸须大于413*626像素!')
            }
		},
        // 图片删除函数
        handleRemove(file, fileList) {
            this.imageUrlDataStamp = ''
            this.BackgroundWebImgUrl = ''
            this.$message.success('图片删除成功')
            var imageHtml = document.getElementsByClassName('el-upload--picture-card')[0]
            imageHtml.style.cssText = 'display: block;'
            var imageHtmlRemo = document.getElementsByClassName('el-upload-list__item is-success')[0]
            imageHtmlRemo.parentNode.removeChild(imageHtmlRemo)
        },
        // 获取数据字典岗位、行业
        getDataDictionaryList() {
            var params = {}
            this.postTypeArr = []
            this.industryTypeArr = []
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    result.Response.forEach(item => {
                        if(item.DataType == 0 && item.IsEnable) {
                            this.industryTypeArr.push(item)
                        }
                        if(item.DataType == 1 && item.IsEnable) {
                            this.postTypeArr.push(item)
                        }
                    });
                }
            })
        },
        searchFun(val) {
            this.getSocialUnitFun(val)
        },
        // 获取社会单位
        getSocialUnitFun(val) {
            var params = {
                socialUnitName: val
            }
            getSocialUnitListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.SocialUnitList = result.Response.Data
                }
            })
        },
        // 根据手机号获取该手机号是否是应训人员
        getPersonTypeByPhone(phone) {
            var params = {
                phoneNumber: phone
            }
            getCheckIsSocialUnitStaff(params).then(res => {
                var result = res.data
                if(result.Success) {
                    if(result.Response.IsSocialUnitStaff == 1) {
                        if(result.Response.TradeID) {
                            var newCode = ''
                            this.industryTypeArr.forEach(item => {
                                if(result.Response.TradeID == item.ID) {
                                    newCode = item.Code
                                }
                            })
                            this.isDisabled = true
                            this.dialogForm.industryType = newCode
                        } else {
                            this.isDisabled = false
                        }
                    }
                }
            })
        },
        // 身份证input失焦触发
        onBlur(val) {
            if(this.strlen(val) == 18){
                this.dialogForm.userSex = util.getAnalysisIdCard(val,2)
            }
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible45 = true;
        },
        getToken() {
            return localStorage.getItem("Token")
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.PayTime || row.PayTime == ""
            ? ""
            : util.formatDate.format(new Date(row.PayTime), "yyyy-MM-dd hh:mm");
        },
        strlen(str){
            var len = 0;
            for (var i=0; i<str.length; i++) { 
            var c = str.charCodeAt(i); 
            //单字节加1 
            if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) { 
            len++; 
            } 
            else { 
                len+=2; 
            } 
            } 
            return len;
        }
    },
    computed: {
        headers() {
            return{
                "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
            }
        }
    },
    watch: {
        'dialogForm.unitName': function(val) {
            this.SocialUnitList.forEach(item => {
                if(item.ID == val){
                    this.dialogForm.unitAddress = item.Address
                }
            })
        },
        'dialogForm.phone': function(val) {
            if(val.length == 11) {
                this.getPersonTypeByPhone(val)
            }
        }
    },
    created(){
        this.uploadImagesUrl = api.uploadImagesUrl
    },
    mounted(){
        this.getData()
        this.getDataDictionaryList()
        // this.getSocialUnitFun()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        height: 100%;
        display: block;
    }
    .social {
        width 100%;
    }
</style>